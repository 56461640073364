import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useState } from "react";
import PrimaryButton from "../Buttons/PrimaryButton";
import Svgs from "../Svgs";
import { cookiePermission } from "../../../utils/constants";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../../utils/helper";

export default function CookieSetting({ open, setOpen }) {
  const cookieConsentPermissions = JSON.parse?.(
    getLocalStorageItem("cookiePermission")
  );
  const [cookieConsentStatus, setCookieConsetStatus] = useState(
    cookieConsentPermissions
  );

  const handleCookie = (e) => {
    setCookieConsetStatus((prev) => {
      return {
        ...prev,
        [e.target.value]: e.target.checked,
      };
    });
  };

  const handleSaveSetting = () => {
    setLocalStorageItem(
      "cookiePermission",
      JSON.stringify(cookieConsentStatus)
    );
    window.dataLayer[0] = cookieConsentStatus;
    setOpen(false);
  };
  return (
    <Transition.Root appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-auto rounded-lg bg-white px-4 pt-3 text-left shadow-xl transition-all sm:mt-12 w-full sm:max-w-2xl max-h-[calc(100dvh-140px)] sm:max-h-[calc(100dvh-100px)]">
                  <div className="flex items-start justify-between pb-2">
                    <Dialog.Title
                      as="h3"
                      className="text-2xl font-semibold text-gray-900"
                    >
                      Choose your cookie preferences
                    </Dialog.Title>
                    <button
                      type="button"
                      className="text-hexitime-textColor2 bg-hexitime-textColor4 hover:bg-hexitime-textColor1 rounded-full text-sm p-1.5 ml-auto inline-flex items-center outline-none"
                      data-modal-hide="defaultModal"
                      onClick={() => setOpen(false)}
                    >
                      <XMarkIcon
                        className="h-4 w-4 text-white"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  <div className="flex flex-col gap-3">
                    <div className="mb-4 flex gap-3 flex-col">
                      <p className="!text-hexitime-textColor2 !text-base ">
                        Some essential features on the Warwick sites just won't
                        work without cookies. And having other cookies switched
                        off can seriously affect the way you'll be able to enjoy
                        our services.
                      </p>
                      <p className="!text-hexitime-textColor2 !text-base ">
                        Please check your cookie settings below and turn on any
                        cookies you're happy with.
                      </p>
                    </div>
                    <div className="px-4 flex flex-col gap-4">
                      <div className="flex flex-col gap-1 mb-1">
                        <div className="flex gap-2">
                          <Svgs
                            icon="Check"
                            fillClass={"w-[18px] fill-black"}
                          />
                          <p className="font-[600] !text-hexitime-textColor2 !text-base">
                            Essentil cookies
                          </p>
                        </div>
                        <p className="!text-hexitime-textColor2 !text-base ">
                          These cookies are required for our website to operate
                          correctly, and are therefore always turned on. You can
                          disable them through your browser but this may
                          negatively affect your browsing experience.
                        </p>
                      </div>
                      {cookiePermission.map((permission, index) => {
                        return (
                          <div
                            className="flex flex-col gap-1 mb-1"
                            key={Math.random() + index}
                          >
                            <div className="flex gap-2">
                              {" "}
                              <input
                                type="checkbox"
                                name="cookies"
                                value={permission.value}
                                checked={
                                  cookieConsentStatus?.[permission.value]
                                }
                                onChange={handleCookie}
                              />
                              <p className="!text-hexitime-textColor2 !text-base font-[600]">
                                {permission.label}
                              </p>
                            </div>
                            <p className="!text-hexitime-textColor2 !text-base font-medium">
                              {permission.description}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="my-5 flex justify-between">
                    <button
                      type="button"
                      className="text-hexitime-primaryRed font-semibold text-base"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>

                    <PrimaryButton
                      btnType="button"
                      btnText="Save Settings"
                      disabled={false}
                      className="xsm:!w-[170px] !w-[auto] !px-[20px]"
                      onClick={handleSaveSetting}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
