import PropTypes from "prop-types";

import {
  ChatContext,
  UserDetailsContext,
  getLocalStorageItem,
  setLocalStorageItem,
  userData,
} from "../utils/helper";
import { useState } from "react";
import { Api } from "../api";

const userProfilePic =
  (getLocalStorageItem("profilePicture") &&
    getLocalStorageItem("profilePicture")) ||
  null;

export const UserDetailsProvider = ({ children }) => {
  const userD = userData();
  const creditRaw = getLocalStorageItem("credit");
  const credit =
    creditRaw && creditRaw !== "undefined"
      ? JSON.parse(creditRaw)
      : { available: 0, freezed: 0 };

  const [profilePicture, setProfilePicture] = useState(userProfilePic);
  const [credits, setCredits] = useState(credit);
  const [isAccessible, setIsAccessible] = useState(false);
  const [usersData, setUsersData] = useState(userD);
  const [userFirebaseId, setUserFirebaseId] = useState("");
  const [userChatDetails, setUserChatDetails] = useState({});
  const [showChat, setShowChat] = useState(false);
  const [chatWithUser, setChatWithUser] = useState(null);
  const [firstVisible, setFirstVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allConversations, setAllConversations] = useState([]);
  const [activityCount, setActivityCount] = useState(0);
  const [allNetworks, setAllNetworks] = useState([]);
  const [flag, setFlag] = useState(false);
  const [isMobileDevice, setMobileDevice] = useState(false);
  const [currentScreenWidth, setCurrentScreenWidth] = useState("");

  const handleUsernameChange = (newUsername, data) => {
    const temp = data ? data : userD;

    const updatedData = { ...temp, user_name: newUsername };
    setUsersData(updatedData);

    setLocalStorageItem("userData", JSON.stringify(updatedData));
  };

  const openChatPopup = (chatData) => {
    setChatWithUser(chatData);
    setShowChat((showChat)=>!showChat);
  };

  const getScheduleActivityCounts = () => {
    Api.getScheduleActivityCounts().then((response) => {
      if (response?.data?.meta?.code === 200) {
        setActivityCount(response?.data?.detail?.user_schedual_activity_count);
      }
    });
  };

  return (
    <UserDetailsContext.Provider
      value={{
        profilePicture,
        setProfilePicture,
        credits,
        setCredits,
        isAccessible,
        setIsAccessible,
        usersData,
        setUsersData,
        handleUsernameChange,
        userFirebaseId,
        setUserFirebaseId,
        userChatDetails,
        setUserChatDetails,
        getScheduleActivityCounts,
        activityCount,
        allNetworks,
        setAllNetworks,
        currentScreenWidth,
        setCurrentScreenWidth
      }}
    >
      <ChatContext.Provider
        value={{
          showChat,
          setShowChat,
          chatWithUser,
          setChatWithUser,
          openChatPopup,
          firstVisible,
          setFirstVisible,
          loading,
          setLoading,
          allConversations,
          setAllConversations,
          flag,
          setFlag,
          isMobileDevice,
          setMobileDevice,
        }}
      >
        {children}
      </ChatContext.Provider>
    </UserDetailsContext.Provider>
  );
};

UserDetailsProvider.propTypes = {
  children: PropTypes.any,
};
